import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/paste-website/src/layouts/DefaultLayout.tsx";
import { graphql } from 'gatsby';
import { Anchor } from '@twilio-paste/anchor';
import { Heading } from '@twilio-paste/heading';
import { Button } from '@twilio-paste/button';
import { Separator } from '@twilio-paste/separator';
import { AspectRatio } from '@twilio-paste/aspect-ratio';
import { Box } from '@twilio-paste/box';
import { Popover, PopoverContainer, PopoverButton } from '@twilio-paste/popover';
import Changelog from '@twilio-paste/popover/CHANGELOG.md';
import { Text } from '@twilio-paste/text';
import { Callout, CalloutTitle, CalloutText } from '../../../components/callout';
import { SidebarCategoryRoutes } from '../../../constants';
import { defaultExample, rightExample } from '../../../component-examples/PopoverExamples';
export const pageQuery = graphql`
  {
    allPasteComponent(filter: {name: {eq: "@twilio-paste/popover"}}) {
      edges {
        node {
          name
          description
          status
          version
        }
      }
    }
    mdx(fields: {slug: {eq: "/components/popover/"}}) {
      fileAbsolutePath
      frontmatter {
        slug
        title
      }
      headings {
        depth
        value
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ComponentHeader = makeShortcode("ComponentHeader");
const PageAside = makeShortcode("PageAside");
const LivePreview = makeShortcode("LivePreview");
const ChangelogRevealer = makeShortcode("ChangelogRevealer");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <ComponentHeader name={props.pageContext.frontmatter.title} categoryRoute={SidebarCategoryRoutes.COMPONENTS} githubUrl="https://github.com/twilio-labs/paste/tree/main/packages/paste-core/components/popover" storybookUrl="/?path=/story/components-popover--default" data={props.data.allPasteComponent.edges} mdxType="ComponentHeader" />
    <hr></hr>
    <contentwrapper>
      <PageAside data={props.data.mdx} mdxType="PageAside" />
      <content>
        <Callout mdxType="Callout">
  <CalloutTitle mdxType="CalloutTitle">Using alpha components in your product</CalloutTitle>
  <CalloutText mdxType="CalloutText">
    Alpha components are ready for you to use in production. Components such as Popover are likely to not see any API
    changes. This is only an alpha due to the lack of documentation and a matching Sketch symbol. Feel free to share
    your experience using this component by{' '}
    <Anchor href="https://github.com/twilio-labs/paste/discussions/new" mdxType="Anchor">starting a discussion on GitHub</Anchor>. We are
    also looking for a contributor to symbolize this component in Sketch. Interested? Reach out!
  </CalloutText>
        </Callout>
        <h2>{`Guidelines`}</h2>
        <h3>{`About Popover`}</h3>
        <p>{`The Popover component is a non-modal dialog that is commonly used for displaying additional rich content
above your UI.`}</p>
        <h3>{`Popover compared to Tooltip`}</h3>
        <Box marginBottom="space70" mdxType="Box">
  <AspectRatio ratio="16:9" mdxType="AspectRatio">
    <iframe src="https://www.loom.com/embed/f70204bd8a4f4f21a5b1ded17f4559d1" frameBorder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowFullScreen={true}></iframe>
  </AspectRatio>
        </Box>
        <h3>{`Accessibility`}</h3>
        <p>{`Popovers and non-modal dialogs follow these accessibility guidelines:`}</p>
        <ul>
          <li parentName="ul">{`There must be a focusable element inside the popover.`}</li>
          <li parentName="ul">{`There should be a close button so screen readers have a specific close action to target.`}</li>
          <li parentName="ul">{`A Popover is a focus trap, and focus is placed inside it when it's shown.`}</li>
          <li parentName="ul">{`A Popover must be triggered by an explicit user action, e.g. clicking a button.`}</li>
        </ul>
        <h2>{`Examples`}</h2>
        <h3>{`Basic Popover`}</h3>
        <LivePreview scope={{
          Popover,
          PopoverContainer,
          PopoverButton,
          Text,
          Heading,
          Separator,
          Button
        }} noInline mdxType="LivePreview">
  {defaultExample}
        </LivePreview>
        <h3>{`Controlling placement`}</h3>
        <p>{`The placement of the popover is configurable via the `}<inlineCode parentName="p">{`placement`}</inlineCode>{` prop. The available placement options are
available in the `}<a parentName="p" {...{
            "href": "#popover-container"
          }}>{`props description here`}</a>{`.`}</p>
        <LivePreview scope={{
          Popover,
          PopoverContainer,
          PopoverButton,
          Text
        }} noInline mdxType="LivePreview">
  {rightExample}
        </LivePreview>
        <h2>{`Anatomy`}</h2>
        <h3>{`PopoverButton`}</h3>
        <p>{`See the `}<a parentName="p" {...{
            "href": "/components/button"
          }}>{`button`}</a>{` component.`}</p>
        <h3>{`Popover`}</h3>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Property`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default token`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Modifiable?`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`background-color`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`color-background-body`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`border-width`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`border-width-10`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`border-color`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`color-border-lighter`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`border-radius`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`border-radius-20`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`box-shadow`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`shadow-card`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`max-width`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`size-30`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`padding`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`space-50`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`padding-left`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`space-70`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`padding-right`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`space-70`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`z-index`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`z-index-80`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
          </tbody>
        </table>
        <hr></hr>
        <h2>{`Usage Guide`}</h2>
        <h3>{`API`}</h3>
        <h4>{`Installation`}</h4>
        <pre><code parentName="pre" {...{
            "className": "language-bash"
          }}>{`yarn add @twilio-paste/popover - or - yarn add @twilio-paste/core
`}</code></pre>
        <h4>{`Usage`}</h4>
        <pre><code parentName="pre" {...{
            "className": "language-jsx"
          }}>{`import {Popover, PopoverContainer, PopoverButton} from '@twilio-paste/popover';

const PopoverExample: React.FC = () => {
  return (
    <PopoverContainer baseId="popover-example">
      <PopoverButton variant="primary">Open popover</PopoverButton>
      <Popover aria-label="Popover">Popover content</Popover>
    </PopoverContainer>
  );
};
`}</code></pre>
        <h4>{`Props`}</h4>
        <h4>{`Popover Container`}</h4>
        <h5><inlineCode parentName="h5">{`baseId?: string`}</inlineCode></h5>
        <p>{`Sets the ID that will serve as a base for all the items IDs.`}</p>
        <h5><inlineCode parentName="h5">{`gutter?: string`}</inlineCode></h5>
        <p>{`Sets the offset between the reference and the popover on the main axis.`}</p>
        <h5><inlineCode parentName="h5">{`placement?: "auto-start" | "auto" | "auto-end" | "top-start...`}</inlineCode></h5>
        <p>{`Sets the placement of popover in relation to the `}<inlineCode parentName="p">{`PopoverButton`}</inlineCode>{`. Available options include:`}</p>
        <ul>
          <li parentName="ul">{`auto-start`}</li>
          <li parentName="ul">{`auto-end`}</li>
          <li parentName="ul">{`auto`}</li>
          <li parentName="ul">{`top-start`}</li>
          <li parentName="ul">{`top-end`}</li>
          <li parentName="ul">{`top`}</li>
          <li parentName="ul">{`bottom-start`}</li>
          <li parentName="ul">{`bottom-end`}</li>
          <li parentName="ul">{`bottom`}</li>
          <li parentName="ul">{`right-start`}</li>
          <li parentName="ul">{`right-end`}</li>
          <li parentName="ul">{`right`}</li>
          <li parentName="ul">{`left-start`}</li>
          <li parentName="ul">{`left-end`}</li>
          <li parentName="ul">{`left`}</li>
        </ul>
        <h4>{`Popover`}</h4>
        <h5><inlineCode parentName="h5">{`aria-label: string`}</inlineCode></h5>
        <p>{`Required label for this Popover component. Titles the entire popover context for screen readers.`}</p>
        <ChangelogRevealer mdxType="ChangelogRevealer">
  <Changelog mdxType="Changelog" />
        </ChangelogRevealer>
      </content>
    </contentwrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      